<template>
  <v-container
    class="background"
    fluid
    fill-height
  >
    <v-row>
      <v-col class="container ma-16">
        <v-row class="header">
          <img
            src="@/assets/back.png"
            alt="뒤로가기"
            type="button"
            @click="$router.replace('/admin-config')"
          >
          <span class="robot-setting-title">&nbsp;&nbsp;&nbsp;API 테스트</span>
          <v-spacer />
          <span
            class="robot-setting-title"
            type="button"
            @click="exe"
          >실행&nbsp;</span>
        </v-row>
        <v-col class="content-area">
          <v-col class="12 pa-0">
            <h3 class="robot-option-title">
              REST API post NAME
            </h3>
            <input
              v-model="api"
              type="text"
              class="input-setting"
              placeholder="API 입력"
            >
            <p class="default-robot-option-text">
              /service <span>(서비스 콜)</span>
            </p>
            <p class="default-robot-option-text">
              /topic/pub <span>(토픽 발행)</span>
            </p>
            <p class="default-robot-option-text">
              /topic/sub <span>(토픽 구독)</span>
            </p>
          </v-col>
          <v-col cols="12 pa-0">
            <h3 class="robot-option-title">
              param
            </h3>
            <input
              v-model="param"
              type="text"
              class="input-setting"
              placeholder="쌍따옴표 작성 필수"
            >
            <p class="default-robot-option-text">
              {"serviceName": "", "serviceType": "", "options": ""} <span>(서비스 콜)</span>
            </p>
            <p class="default-robot-option-text">
              {"topicName": "", "messageType": "", "message": ""} <span>(토픽 발행)</span>
            </p>
            <p class="default-robot-option-text">
              {"topicName": "", "messageType": ""} <span>(토픽 구독)</span>
            </p>
          </v-col>
          <v-col cols="12 pa-0">
            <h3 class="robot-option-title">
              response
            </h3>
            <textarea
              v-model="response"
              class="textarea"
              placeholder=""
            />
          </v-col>

          <v-col cols="12 pa-0">
            <h3 class="robot-option-title">
              자주 사용하는 기능
            </h3>
            <v-select
              v-model="select"
              :items="useList.map((x) => x.split('&&')[0])"
              class="select-input"
              dark
              height="8vh"
              item-color="grey"
              outlined
              placeholder="자주 사용하는 기능 선택"
              @change="changeSelect"
            />
          </v-col>

          <v-col class="12 pa-0">
            <h3 class="robot-option-title">
              연습장
            </h3>
            <textarea
              v-model="test"
              class="textarea"
              placeholder="임시 메모"
            />
          </v-col>

          <v-btn
            class="btn mt-9"
            color="#00BFB4"
            dark
            @click="open"
          >
            REST API Docs 바로가기
          </v-btn>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import EventBus from '@/main.js';

export default {
  name: 'AdminTesting',
  data() {
    return {
      select: null,
      api: '/service',
      param: '{"serviceName": "/rosapi/services", "serviceType": "rosapi/Services", "options": ""}',
      response: null,
      useList: [
        `Conveyor_data_read_cmd&&/service&&{"serviceName": "/@robotNamespace/Conveyor_data_read_cmd", "serviceType": "tetraDS_interface/conveyor_data_read", "options": {"num": 0}}`,
        `Conveyor_parameter_read_cmd&&/service&&{"serviceName": "/@robotNamespace/Conveyor_parameter_read_cmd", "serviceType": "tetraDS_interface/conveyor_parameter_read", "options": {"num": 0}}`,
        `Conveyor_parameter_write_cmd&&/service&&{"serviceName": "/@robotNamespace/Conveyor_parameter_write_cmd", "serviceType": "tetraDS_interface/conveyor_parameter_write", "options": {"num": 0, "data": 0}}`,
        `Power_data_read_cmd&&/service&&{"serviceName": "/@robotNamespace/Power_data_read_cmd", "serviceType": "tetraDS_interface/power_data_read", "options": {"num": 0}}`,
        `Power_parameter_read_cmd&&/service&&{"serviceName": "/@robotNamespace/Power_parameter_read_cmd", "serviceType": "tetraDS_interface/power_parameter_read", "options": {"num": 0}}`,
        `Power_parameter_write_cmd&&/service&&{"serviceName": "/@robotNamespace/Power_parameter_write_cmd", "serviceType": "tetraDS_interface/power_parameter_write", "options": {"num": 0, "data": 0}}`,
        `param_read_cmd&&/service&&{"serviceName": "/@robotNamespace/param_read_cmd", "serviceType": "tetraDS/parameter_read", "options": {"num": 0}}`,
        `param_write_cmd&&/service&&{"serviceName": "/@robotNamespace/param_write_cmd", "serviceType": "tetraDS/parameter_write", "options": {"num": 0, "data": 0}}`,
      ],
      test: `
        /service

          {"serviceName": "/rosapi/services", "serviceType": "rosapi/Services", "options": ""}

          {"serviceName": "/rosapi/service_type", "serviceType": "string", "options": {"service": "/rosapi/services"}}

          {"serviceName": "/rosapi/service_request_details", "serviceType": "rosapi/ServiceRequestDetails", "options": {"type": "rosapi/Services"}}

          {"serviceName": "/@robotNamespace/Conveyor_data_read_cmd", "serviceType": "tetraDS_interface/conveyor_data_read", "options": {"num": 0}}

          {"serviceName": "/@robotNamespace/Conveyor_parameter_read_cmd", "serviceType": "tetraDS_interface/conveyor_parameter_read", "options": {"num": 0}}

          {"serviceName": "/@robotNamespace/Conveyor_parameter_write_cmd", "serviceType": "tetraDS_interface/conveyor_parameter_write", "options": {"num": 0, "data": 0}}

          {"serviceName": "/@robotNamespace/Power_data_read_cmd", "serviceType": "tetraDS_interface/power_data_read", "options": {"num": 0}}

          {"serviceName": "/@robotNamespace/Power_parameter_read_cmd", "serviceType": "tetraDS_interface/power_parameter_read", "options": {"num": 0}}

          {"serviceName": "/@robotNamespace/Power_parameter_write_cmd", "serviceType": "tetraDS_interface/power_parameter_write", "options": {"num": 0, "data": 0}}

          {"serviceName": "/@robotNamespace/param_read_cmd", "serviceType": "tetraDS/parameter_read", "options": {"num": 0}}

          {"serviceName": "/@robotNamespace/param_write_cmd", "serviceType": "tetraDS/parameter_write", "options": {"num": 0, "data": 0}}


        /topic/pub

          {"topicName": "/@robotNamespace/cmd_vel", "messageType": "geometry_msgs/Twist", "message": {"linear": {"x": 0.1, "y": 0.0, "z": 0.0}, "angular": {"x": 0.0, "y": 0.0, "z": 0.0}}}


        /topic/sub

          {"topicName": "/@robotNamespace/gpio_status", "messageType": "tetraDS_interface/GPIO"}
      `,
    }
  },
  destroyed() {
    EventBus.$off('topic/admin');
  },
  created() {
    EventBus.$off('topic/admin');
    EventBus.$on('topic/admin', (data)=> {
      this.response = JSON.stringify(data);
    });
  },
  computed: {
    robotNamespace(){
      return this.$store.state.robot_namespace;
    },
  },
  watch: {
    robotNamespace(){
      this.test= this.test.replace('@robotNamespace', this.$store.state.robot_namespace);
    },
  },
  methods: {
    open(){
      window.open('/api-docs/');
    },
    exe() {
      this.$axios.post(this.api, JSON.parse(this.param))
        .then(res => {
          if (res.status === 200) {
            this.response = JSON.stringify(res.data);
          }
        })
        .catch(err => this.response = err);
    },
    changeSelect(){
      this.useList.forEach((item)=>{
        const arr= item.split("&&");
        if(this.select=== arr[0]){
          this.api= arr[1];
          this.param= arr[2].toString().replace('@robotNamespace', this.robotNamespace);
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.background {
  overflow: hidden;
  background-color: #393939;
  word-break: keep-all;
}

.container {
  height: 100vh;
}

.robot-setting-title {
  color: #ffffff;
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 80px;
}

.content-area {
  height: 60vh;
  text-align: center;
  overflow: auto;
}

.robot-option-title {
  font-size: 2rem;
  color: white;
}

.input-setting {
  width: 70%;
  height: 80px;
  padding: 20px;
  border: 2px solid #545861;
  background-color: black;
  font-size: 3rem;
  color: white;
}

.input-setting:focus {
  outline: solid;
  outline-color: #9aa0ae;
}

.textarea {
  width: 70%;
  height: 180px;
  padding: 20px;
  border: 2px solid #545861;
  background-color: black;
  font-size: 1rem;
  color: white;
}

.textarea:focus {
  outline: solid;
  outline-color: #9aa0ae;
}

.default-robot-option-text {
  display: -webkit-inline-box;
  margin: 0;
  width: 70%;
  font-size: 1.5rem;
  color: white;
}

.default-robot-option-text > span {
  font-size: 1rem;
}

@media (max-width: 1024px) {
  .header img {
    width: 30px;
    height: 30px;
  }

  .robot-setting-title {
    font-size: 20px !important;
  }

  .input-setting {
    width: 70%;
    height: 30px;
    padding: 20px;
    border: 2px solid #545861;
    background-color: black;
    font-size: 2rem;
    color: white;
  }

  .textarea {
    width: 70%;
    height: 120px;
    padding: 20px;
    border: 2px solid #545861;
    background-color: black;
    font-size: 1rem;
    color: white;
  }

  .default-robot-option-text {
    display: -webkit-inline-box;
    margin: 0;
    width: 70%;
    font-size: 1.0rem;
    color: white;
  }

  .default-robot-option-text > span {
    font-size: 0.7rem;
  }
}
</style>
